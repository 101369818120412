<template>
  <div class="custom-input-file align-center justify-center">
    <div style="position:relative;" class="d-flex flex-column align-center fadeIn">
      <label :for="inputId" :id="`${inputId}Label`">
        <v-icon class="icon-arrow">fas fa-upload</v-icon>
      </label>
      <input :id="inputId" @change="printImage" type="file" class="input-file" :ref="inputRef">
      <p class="mt-6">Arrastra y suelta una imagen para subirla</p>
      <v-btn class="mt-3" style="width: 230px;" color="primary">
        <label style="width: 230px; height: 36px; padding-top: 11px; cursor:pointer;" :for="inputId">Seleccionar imagen</label>
      </v-btn>
    </div>
    <div class="fadeIn" style="margin-top: 40px">
      <iframe v-if="fileTmpRender" :src="`${URL_API_IMAGES}${fileTmpRender}`" style="width: 100%; height: 550px"/>
      <iframe v-if="fileBase64" :src="fileBase64" style="width: 100%; height: 500px" />
    </div>
  </div>
</template>

<script>
import { URL_API_IMAGES } from "@/store/config";

export default {
  name: "FileInputEditNew",
  computed: {
    URL_API_IMAGES() {
      return URL_API_IMAGES;
    },
    inputId() {
      return `inputImage-${this.id}`;
    },
    inputRef() {
      return `fileImage-${this.id}`;
    }
  },
  props: {
    showImage: Boolean,
    tenderId: Number,
    formatosId: Number,
    file: [File, String],
    fileTmp: [File, String],
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fileBase64: null,
      fileTmpRender: this.fileTmp
    };
  },
  watch: {
    fileTmp: {
      immediate: true,
      handler(newFileTmp) {
        this.fileTmpRender = newFileTmp;
      }
    },
    file: {
      immediate: true,
      handler(newFile) {
        if (newFile instanceof File) {
          this.getBase64(newFile).then(base64 => {
            this.fileBase64 = base64;
          });
        } else {
          this.fileBase64 = null;
        }
      }
    }
  },
  methods: {
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async printImage(e) {
      const file = this.$refs[this.inputRef].files[0];
      try {
        const fileBase64 = await this.getBase64(file);
        this.fileBase64 = fileBase64;
        this.fileTmpRender = null;
        this.passImageFile(file);
      } catch (error) {
        console.error("Error al cargar la imagen:", error);
      }
    },
    passImageFile(file) {
      this.$emit('getImageFile', file);
    }
  }
};
</script>

<style scoped>
/*#fileInput{*/
/*  width: 400px;*/
/*  height: 400px;*/
/*  background: #ff5252;*/
/*  border: none;*/
/*}*/
.custom-input-file {
  /*background-color: transparent;*/
  /*color: #fff;*/
  /*cursor: pointer;*/
  /*font-size: 18px;*/
  /*font-weight: bold;*/
  /*margin: 0 auto 0;*/
  min-height: 400px;
  overflow: hidden;
  /*padding: 10px;*/
  position: relative;
  text-align: center;
  width: 100%;
}

.custom-input-file .input-file {
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  cursor: pointer;
  /*font-size: 100px;*/
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  /*position: absolute;*/
  /*right: -1000px;*/
  /*top: -1000px;*/
}

.icon-arrow {
  font-size: 50px;
  background: #f2f2f2;
  padding: 50px;
  border-radius: 999px;
  cursor: pointer;
}

#inputImageLabel {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#icon-close {
  position: absolute;
  cursor: pointer;
  right: 0px;
  color: #FF5252;
}
</style>