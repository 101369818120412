<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Dictámen</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons
            :tenderId="this.tenderId"
            :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Dictamen economico
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <FileInputEdit
                            :tenderId="this.tenderId"
                            :formatosId="this.formatosId"
                            :file="data.dictamen_url"
                            :file-tmp="fileTmp"
                            @getImageFile="handleImageFile"
                            @changeShowImage="handleShowImage"
                        />
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>


                </v-expansion-panels>
                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col v-if="fileTmp" cols="6" class="mt-6">
                  <v-btn @click="downloadImage" type="button" color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#dic" @click="findOneDoc">
            DICTAMEN PRESENTACIÓN DE DOCUMENTOS LEGALES Y FORMALES
          </v-tab>
          <v-tab-item value="dic">
            <v-form ref="form" class="mt-10" @submit.prevent="submitFormDoc">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion</h1>
                        <trumbowyg
                            v-model="dataDoc.descripcion"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Descripcion"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Documentacion</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Documentacion</h1>
                        <trumbowyg
                            v-model="dataDoc.documentos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Documentacion"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Manifestando</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Manifestando</h1>
                        <trumbowyg
                            v-model="dataDoc.proveedores"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Manifestando"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-col cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataDoc" v-if="idDoc" color="primary"
                         style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatusDoc"
                         @click="downloadFileServerDoc" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatusDoc">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerateDoc"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab href="#dicAd" @click="findTypeData('doc')">
            DICTAMEN PRESENTACIÓN (Abjunto)
          </v-tab>
          <v-tab-item value="dicAd">
            <div v-if="fileDoc">
              <v-form v-if="!fileDoc.id" ref="form" class="mt-10" @submit.prevent="createDocDocument">
                <v-row class="mb-4">
                  <v-col cols="12">
                    <FileInput
                        :tenderId="tenderId"
                        :formatosId="fileDoc.formatosId"
                        :file="fileDoc.dictamen_url"
                        @getImageFile="handleImageFileDoc"
                        @changeShowImage="handleShowImageDoc"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <v-btn type="submit" color="primary" style="width: 200px">
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormDocUpdate">
                <v-row class="mb-4" v-if="!overlay">
                  <v-col cols="12">
                    <FileInputEditNew
                        id="edit-doc"
                        :tenderId="this.tenderId"
                        :formatosId="this.formatosId"
                        :file="fileDoc.dictamen_url"
                        :file-tmp="fileDoc.fileTmpDoc"
                        @getImageFile="handleImageFileDoc"
                    />
                  </v-col>
                  <v-col v-if="fileDoc.fileTmpDoc" cols="6" class="mt-6">
                    <v-btn @click="downloadImageFiles(fileDoc.dictamen_url)" type="button" color="primary"
                           style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col v-if="!fileDoc.fileTmpDoc" cols="6" class="mt-6">
                    <v-btn type="submit" color="primary" style="width: 200px">
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-tab-item>
          <v-tab href="#dicTec" @click="findOneTec">
            DICTAMENTE TECNICO
          </v-tab>
          <v-tab-item value="dicTec">
            <v-row class="mt-5">
              <v-form ref="form" class="mt-10" @submit.prevent="submitFormTec">
                <v-row class="mb-4">
                  <v-expansion-panels inset>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>Descripcion</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <h1 class="mt-4 mx-2 ">Descripcion</h1>
                          <trumbowyg
                              v-model="dataTec.descripcion"
                              name="body"
                              :config="configs.advanced"
                              placeholder="Descripcion"
                              class="form-control"
                          ></trumbowyg>
                        </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>Requerimientos</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <h1 class="mt-4 mx-2 ">Requerimientos</h1>
                          <trumbowyg
                              v-model="dataTec.requerimiento"
                              name="body"
                              :config="configs.advanced"
                              placeholder="Requerimientos"
                              class="form-control"
                          ></trumbowyg>
                        </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>Especificaciones tecnica</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <h1 class="mt-4 mx-2 ">Especificaciones tecnica</h1>
                          <trumbowyg
                              v-model="dataTec.especificaciones_tecnica"
                              name="body"
                              :config="configs.advanced"
                              placeholder="Especificaciones tecnica"
                              class="form-control"
                          ></trumbowyg>
                        </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>Documento a entregar</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <h1 class="mt-4 mx-2 ">Documento a entregar</h1>
                          <trumbowyg
                              v-model="dataTec.documentos_entregar"
                              name="body"
                              :config="configs.advanced"
                              placeholder="Documento a entregar"
                              class="form-control"
                          ></trumbowyg>
                        </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                      <v-expansion-panel-header>Propuestas presentadas</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <h1 class="mt-4 mx-2 ">Propuestas presentadas</h1>
                          <trumbowyg
                              v-model="dataTec.propuestas_presentadas"
                              name="body"
                              :config="configs.advanced"
                              placeholder="Propuestas presentadas"
                              class="form-control"
                          ></trumbowyg>
                        </v-col>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-col cols="6" class="mt-6">
                    <v-btn type="submit" color="primary" style="width: 200px">
                      Guardar
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="mt-6">
                    <v-btn @click="generateDataTec" v-if="idTec" color="primary"
                           style="width: 400px">
                      Generar Archivo
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="3">
                    <v-btn type="button" v-if="documentGenerateStatusTec"
                           @click="downloadFileServerTec" color="primary"
                           style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="documentGenerateStatusTec">
                    <h1 class="mt-4 mx-2 ">Visualización de documento
                    </h1>
                    <trumbowyg
                        v-model="documentGenerateTec"
                        disabled="disabled"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Visualización de documento"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-tab-item>
          <v-tab href="#dicTecAd" @click="findTypeData('tec')">
            DICTAMEN TECNICO (Abjunto)
          </v-tab>
          <v-tab-item value="dicTecAd">
            <div v-if="fileTec.tenderId">
              <v-form v-if="!fileTec.id" ref="form" class="mt-10" @submit.prevent="createTecDocument">
                <v-row class="mb-4">
                  <v-col cols="12">
                    <FileInput
                        :tenderId="tenderId"
                        :formatosId="fileTec.formatosId"
                        :file="fileTec.dictamen_url"
                        @getImageFile="handleImageFileTec"
                        @changeShowImage="handleShowImageTec"
                    />
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <v-btn type="submit" color="primary" style="width: 200px">
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormTecUpdate">
                <v-row class="mb-4" v-if="!overlay">
                  <v-col cols="12">
                    <FileInputEditNew
                        id="edit-tec"
                        :tenderId="this.tenderId"
                        :formatosId="this.formatosId"
                        :file="fileTec.dictamen_url"
                        :file-tmp="fileTec.fileTmpTec"
                        @getImageFile="handleImageFileTec"
                    />
                  </v-col>
                  <v-col v-if="fileTec.fileTmpTec" cols="6" class="mt-6">
                    <v-btn @click="downloadImageFiles(fileTec.dictamen_url)" type="button" color="primary"
                           style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col v-if="!fileTec.fileTmpTec" cols="6" class="mt-6">
                    <v-btn type="submit" color="primary" style="width: 200px">
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import FileInputEdit from "@/components/FileInputEdit.vue";
import {URL_API_IMAGES} from "@/store/config";
import FileInputEditMul from "@/components/FileInputEditMul.vue";
import FileInputEditNew from "@/components/FileInputEditNew.vue";

export default {
  name: "DetailOpinions",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      documentComplete: null,
      documentGenerate: null,
      fileTmp: null,


      documentGenerateStatus: false,
      documentGenerateDoc: null,
      documentGenerateStatusDoc: false,
      documentGenerateTec: null,
      documentGenerateStatusTec: false,
      id: null,
      formatosId: null,
      idDoc: null,
      idTec: null,
      fileDoc: {
        id: null,
        tenderId: null,
        formatosId: 35,
        dictamen_url: "",
        typeDocument: 'doc',
        fileTmpDoc: null,
      },
      fileTec: {
        id: null,
        tenderId: null,
        formatosId: 36,
        dictamen_url: "",
        typeDocument: 'tec',
        fileTmpTec: null,
      },
      dataDoc: {
        descripcion: "",
        documentos: "",
        proveedores: "",
        formatosId: 33,
        tenderId: null,
      },
      dataTec: {
        descripcion: "",
        requerimiento: "",
        especificaciones_tecnica: "",
        documentos_entregar: "",
        propuestas_presentadas: "",
        formatosId: 34,
        tenderId: null,
      },
      data: {
        dictamen_url: "",
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    FileInputEditMul,
    FileInputEdit,
    CheckButtons,
    FileInput,
    Overlay,
    Trumbowyg,
    FileInputEditNew
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('opinions', ['updateOpinions', 'findOneOpinions', 'generateHtmlFormat', 'downloadFile', 'findOneOpinionsType', 'createOpinionsType']),
    ...mapActions('presentationOpinions', ['createOpeningPresentation', 'updateOpeningPresentation', 'findOneOpeningPresentationTender', 'createOpeningPresentation', 'downloadFilePe', 'generateHtmlFormatDoc']),
    ...mapActions('technicalOpinions', ['createOpeningTechnical', 'updateOpeningTechnical', 'findOneOpeningTechnicalTender', 'downloadFileTec', 'generateHtmlFormatTec']),
    ...mapActions('tenders', ['findOneTenders']),
    handleImageFileDoc(file) {
      this.fileDoc.dictamen_url = file;
      this.fileDoc.fileTmpDoc = '';
    },

    handleShowImageDoc(show) {
    },
    handleImageFileTec(file) {
      this.fileTec.dictamen_url = file;
      this.fileTec.fileTmpTec = '';
    },

    handleShowImageTec(show) {
    },
    async updateDocDocument() {
      this.overlay = true;
      const response = await this.createOpinionsType(this.fileDoc);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.findTypeData('doc');
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async createDocDocument() {
      this.overlay = true;
      const response = await this.createOpinionsType(this.fileDoc);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.findTypeData('doc');
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async createTecDocument() {
      this.overlay = true;
      const response = await this.createOpinionsType(this.fileTec);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.findTypeData('tec');
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async findTypeData(type) {
      const response = await this.findOneOpinionsType({type: type, tenderId: this.tenderId});
      if (type === 'tec') {
        if (response) {
          this.fileTec.id = response.id;
          this.fileTec.dictamen_url = response.dictamen_url;
          this.fileTec.formatosId = response.formatosId;
          this.fileTec.tenderId = response.licitacionesId;
          this.fileTec.typeDocument = response.typeDocumento;
          this.fileTec.fileTmpTec = response.dictamen_url;

        } else {
          this.fileTec.tenderId = this.tenderId;
        }

      }

      if (type === 'doc') {
        if (response) {
          this.fileDoc.id = response.id;
          this.fileDoc.dictamen_url = response.dictamen_url;
          this.fileDoc.formatosId = response.formatosId;
          this.fileDoc.tenderId = response.licitacionesId;
          this.fileDoc.typeDocument = response.typeDocumento;
          this.fileDoc.fileTmpDoc = response.dictamen_url;

        } else {
          this.fileDoc.tenderId = this.tenderId;
        }
      }


    },
    async submitFormDoc() {

      this.overlay = true;
      if (this.idDoc) {
        const response = await this.updateOpeningPresentation({id: this.idDoc, data: this.dataDoc});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneDoc();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createOpeningPresentation(this.dataDoc);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneDoc();
        } else {
          this.sweetAlertError('Error al crear')
        }

      }

      this.overlay = false;
    },
    async submitFormTec() {

      this.overlay = true;
      if (this.idTec) {
        const response = await this.updateOpeningTechnical({id: this.idTec, data: this.dataTec});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneTec();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createOpeningTechnical(this.dataTec);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneTec();
        } else {
          this.sweetAlertError('Error al crear')
        }

      }

      this.overlay = false;
    },
    async findOneDoc() {
      const response = await this.findOneOpeningPresentationTender(this.tenderId);
      if (response) {
        this.idDoc = response.id
        this.dataDoc.proveedores = response.proveedores;
        this.dataDoc.descripcion = response.descripcion;
        this.dataDoc.documentos = response.documentos;
      } else {
        this.dataDoc.tenderId = this.tenderId;
      }
    },
    async findOneTec() {
      const response = await this.findOneOpeningTechnicalTender(this.tenderId);
      if (response) {
        this.idTec = response.id
        this.dataTec.descripcion = response.descripcion;
        this.dataTec.especificaciones_tecnica = response.especificaciones_tecnica;
        this.dataTec.documentos_entregar = response.documentos_entregar;
        this.dataTec.propuestas_presentadas = response.propuestas_presentadas;
        this.dataTec.requerimiento = response.requerimiento;
      } else {
        this.dataTec.tenderId = this.tenderId;
      }
    },
    async downloadImage() {
      try {
        const url = `${URL_API_IMAGES}${this.data.dictamen_url}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },

    handleImageFile(file) {
      this.data.dictamen_url = file;
      this.fileSend = file;
      this.fileTmp = '';
    },

    handleShowImage(show) {
    },
    async findMarketOne() {
      const response = await this.findOneOpinions({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.id = response.id
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.dictamen_url = response.dictamen_url;
        this.fileTmp = response.dictamen_url;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal) {
          this.externalId = responseExternal.claveLicitacion;
        } else {
          this.externalId = null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired() {
      if (!this.data.dictamen_url) {
        this.sweetAlertWarning('Campo documento es necesario');
        return false
      }

      return true

    },
    async submitFormDocUpdate() {

      this.overlay = true;
      const response = await this.updateOpinions({id: this.fileDoc.id, data: this.fileDoc});
      if (!response.error) {
        await this.findTypeData('doc');
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async submitFormTecUpdate() {

      this.overlay = true;
      const response = await this.updateOpinions({id: this.fileTec.id, data: this.fileTec});
      if (!response.error) {
        await this.findTypeData('tec');
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async submitForm() {
      const validateData = await this.validateRequired();
      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.updateOpinions({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async generateDataDoc() {
      this.overlay = true;
      const response = await this.generateHtmlFormatDoc({id: this.idDoc});
      if (!response.error) {
        this.documentGenerateDoc = response;
        this.documentGenerateStatusDoc = true;
      } else {
        this.documentGenerateDoc = response;
        this.documentGenerateStatusDoc = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerDoc() {
      this.overlay = true;
      await this.downloadFilePe({id: this.idDoc, externalId: this.externalId});
      this.overlay = false;
    },
    async generateDataTec() {
      this.overlay = true;
      const response = await this.generateHtmlFormatTec({id: this.idTec});
      if (!response.error) {
        this.documentGenerateTec = response;
        this.documentGenerateStatusTec = true;
      } else {
        this.documentGenerateTec = response;
        this.documentGenerateStatusTec = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerTec() {
      this.overlay = true;
      await this.downloadFileTec({id: this.idTec, externalId: this.externalId});
      this.overlay = false;
    },
    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadImageFiles(data) {
      try {
        const url = `${URL_API_IMAGES}${data}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },
    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id, externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming() {
      this.sweetAlertWarning('Proximamente')
    }
  }
  ,
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
